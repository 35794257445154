import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import styles from "./TextReveal.module.scss";

export const TextReveal = ({ children, color }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: false });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    } else {
      mainControls.start("hidden");
      slideControls.start("hidden");
    }
  }, [isInView, mainControls, slideControls]);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        width: "fit-content",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {/* mainControls */}
      <motion.div
        variants={{
          hidden: { opacity: 0, y: "4svh" },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        style={{ height: "100%" }}
      >
        {children}
      </motion.div>

      {/* slideControls */}
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className={`${styles.revealSlide} ${styles[color]}`}
      />
    </div>
  );
};
