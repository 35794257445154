import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import Navigation from "../Navigation/Navigation";

import logo from "../../assets/icons/logo.svg";

export const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const moveHeader = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setIsVisible(false); // 下にスクロール
      } else if (currentScrollY < lastScrollY) {
        setIsVisible(true); // 上にスクロール
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", moveHeader);

    return () => {
      window.removeEventListener("scroll", moveHeader);
    };
  }, [lastScrollY]);

  return (
    <header className={`${isVisible ? styles.visible : styles.invisible}`}>
      <Navigation logo={logo} />
    </header>
  );
};
