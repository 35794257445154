import React from "react";
import styles from "./PaginationButton.module.scss";

export const PaginationButton = (props) => {
  const { onClick, disabled, forward } = props;

  return (
    <div className={styles.buttonContainer}>
      <button
        className={styles.paginationButton}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={`${styles[forward ? "forward" : "back"]}`}></span>
      </button>
    </div>
  );
};
