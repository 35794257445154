import React, { useEffect, useState } from "react";

import { Parallax } from "react-scroll-parallax";

import { ScrollTrigger } from "gsap/ScrollTrigger";

// Styles
import "../styles/top.scss";

// Images
import { images } from "../assets/images";

// Components
import { Helmet } from "react-helmet-async";
import { DelayHero } from "../components/DelayHero/DelayHero";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { TextReveal } from "../components/TextReveal/TextReveal";
import { Button } from "../components/Button/Button";
import { ExpandableCard } from "../components/ExpandableCard/ExpandableCard";
import { SectionIntro } from "../components/SectionIntro/SectionIntro";
import { ScrollGallery } from "../components/ScrollGallery/ScrollGallery";
import { getNews } from "../services/api";

const Top = () => {
  const [isParallaxDisabled, setIsParallaxDisabled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsParallaxDisabled(isMobile);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 初回実行

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    getNews().then((data) => setNewsList(data));
  }, []);

  useEffect(() => {
    // ページに入るときにScrollTriggerをクリアして初期化
    ScrollTrigger.getAll().forEach((st) => st.kill());
    ScrollTrigger.clearScrollMemory();

    // ページからフォーカスが外れた場合と戻った場合の処理
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // ページがまた見えるようになったとき
        ScrollTrigger.refresh();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // コンポーネントのアンマウント時のクリーンアップ
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      ScrollTrigger.getAll().forEach((st) => st.kill());
      ScrollTrigger.clearScrollMemory();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroTop}
        jaHeadline={
          isParallaxDisabled ? "人生の\n主人公になろう" : "人生の主人公になろう"
        }
        copy={
          isParallaxDisabled
            ? "常識に囚われない、自由な発想で挑戦し続ける"
            : "常識に囚われない、自由な発想で挑戦し続ける"
        }
        showCopy={true}
      />

      <section id="section-about">
        <div className="section-intro">
          <Parallax speed={10} className="intro-content-wrapper">
            <div className="section-intro-content">
              <div className="section-intro-header">
                <TextReveal color="secondary">
                  <h1 className="en-Medium">about</h1>
                </TextReveal>

                <TextReveal color="secondary">
                  <h2 className="ja-Large fadeIn">
                    日本一カッコいい
                    <br />
                    軽貨物会社を創る
                  </h2>
                </TextReveal>
              </div>

              <TextReveal>
                <h3 className="ja-Medium">カッコいいとはこういうコトさ</h3>
              </TextReveal>

              <TextReveal>
                <p className="ja-Small">
                  自分の行動や選択に責任をもっている。
                  <br />
                  見た目と生き方にこだわりがある。
                  <br />
                  心から自画自賛している。
                  <br />
                  愛に溢れている。
                </p>
              </TextReveal>

              <TextReveal>
                <p className="ja-Small">
                  人生の主人公は「自分」と言えること。
                  <br />
                  それを私たちは「カッコいい」と呼んでいる。
                </p>
              </TextReveal>

              <TextReveal>
                <p className="ja-Small">
                  「軽貨物ドライバーの仕事をやっている」と
                  <br />
                  誇りを持って語れる人で溢れた組織をつくる。
                </p>
              </TextReveal>

              <Button text="See more" link="/about" />
            </div>
          </Parallax>

          <div className="hidden-intro-header">
            <div className="section-intro-header">
              <TextReveal color="secondary">
                <h1 className="en-Medium">about</h1>
              </TextReveal>
              <TextReveal color="secondary">
                <h2 className="ja-Large">
                  日本一カッコいい
                  <br />
                  軽貨物会社を創る
                </h2>
              </TextReveal>
            </div>
            <TextReveal>
              <h3 className="ja-Medium">カッコいいとはこういうコトさ</h3>
            </TextReveal>
          </div>

          <Parallax
            speed={20}
            disabled={isParallaxDisabled}
            className="intro-picture"
          >
            <img src={images.heroAbout} alt="heroAbout" />
          </Parallax>

          <div className="hidden-intro-body">
            <TextReveal>
              <p className="ja-Small">
                自分の行動や選択に責任をもっている。
                <br />
                見た目と生き方にこだわりがある。
                <br />
                心から自画自賛している。
                <br />
                愛に溢れている。
              </p>
            </TextReveal>
            <TextReveal>
              <p className="ja-Small">
                人生の主人公は「自分」と言えること。
                <br />
                それを私たちは「カッコいい」と呼んでいる。
              </p>
            </TextReveal>
            <TextReveal>
              <p className="ja-Small">
                「軽貨物ドライバーの仕事をやっている」と
                <br />
                誇りを持って語れる人で溢れた組織をつくる。
              </p>
            </TextReveal>

            <Button text="See more" link="/about" />
          </div>
        </div>

        <ScrollGallery
          id="about-gallery"
          index={0}
          cards={[
            {
              subtitle: "01",
              title: "Mission",
              imageSrc: images.mission,
              link: "/about#mission",
              lang: "en",
            },
            {
              subtitle: "02",
              title: "Vision",
              imageSrc: images.vision,
              link: "/about#vision",
              lang: "en",
            },
            {
              subtitle: "03",
              title: "Value",
              imageSrc: images.value,
              link: "/about#value",
              lang: "en",
            },
          ]}
        />
      </section>

      <section id="section-services">
        <SectionIntro
          image={images.heroService}
          eyebrow="services"
          headline={
            <>
              当たり前のことへの
              <br />
              プロ意識
            </>
          }
          body={
            <>
              特別なことを行うわけではない。
              <br />
              気持ち良い挨拶、コミュニケーション、荷物を丁寧に時間通りに。
              <br />
              高いクオリティで、プロ意識をもって、当たり前のことに妥協せず
              <br />
              常に顧客の期待を超えている配送サービスの提供を行う。
            </>
          }
          link="/services"
        />

        <ScrollGallery
          id="services-gallery"
          index={1}
          title="３つの約束"
          cards={[
            {
              subtitle: "01",
              title: "プロフェッショナルな配送",
              imageSrc: images.strength1,
              link: "/services#promise",
              lang: "ja",
            },
            {
              subtitle: "02",
              title: "期待を超える配送",
              imageSrc: images.strength2,
              link: "/services#promise",
              lang: "ja",
            },
            {
              subtitle: "03",
              title: "プライドをもった在り方",
              imageSrc: images.strength3,
              link: "/services#promise",
              lang: "ja",
            },
          ]}
        />
      </section>

      <section id="section-recruit">
        <SectionIntro
          image={images.heroRecruit2}
          eyebrow="recruit"
          headline={
            <>
              今度はあなたが
              <br />
              主人公になる番だ
            </>
          }
          body={
            <>
              私たちは見た目や在り方が、唯一無二で尖った会社を目指す。
              <br />
              業界のイメージ、仕事の価値観を変えて、
              <br />
              軽貨物ドライバーという仕事を誇れる世界に。
              <br />
              ただの軽貨物会社じゃない。
              <br />
              日本一カッコいい軽貨物会社をともにつくろう。
            </>
          }
          link="/recruit"
        />

        <ScrollGallery
          id="recruit-gallery"
          index={2}
          title="社員の声"
          cards={[
            {
              subtitle: "プロドライバー",
              title: "今まで経験のした事がないほどの充実感。",
              imageSrc: images.employee1,
              link: "/recruit#voice",
              lang: "ja",
              extraContent: (
                <>
                  <h4 className="en-Medium">Kosuke Maruyama</h4>
                  <h4 className="ja-Small">圓山 洸輔</h4>
                </>
              ),
            },
            {
              subtitle: "ドライバー管理 / 採用",
              title: "熱い想いをもっている人たちがいます。",
              imageSrc: images.employee2,
              link: "/recruit#voice",
              lang: "ja",
              extraContent: (
                <>
                  <h4 className="en-Medium">Ryuki Oishi</h4>
                  <h4 className="ja-Small">大石 龍樹</h4>
                </>
              ),
            },
            {
              subtitle: "バックオフィス / 経理",
              title: "「挑戦したい」という気持ちを全員が尊重してくれます。",
              imageSrc: images.employee3,
              link: "/recruit#voice",
              lang: "ja",
              extraContent: (
                <>
                  <h4 className="en-Medium">Rika Kato</h4>
                  <h4 className="ja-Small">加藤 里香</h4>
                </>
              ),
            },
          ]}
        />
      </section>

      <section id="section-information">
        <div className="section-information-header">
          <div className="section-intro-header">
            <h1 className="en-Medium">information</h1>
            <h2 className="ja-Large">お知らせ</h2>
          </div>
          <p className="ja-Small">
            各種活動、メディア掲載、採用情報など、
            <br />
            YUMJAMからのインフォメーションをお伝えします。
          </p>
        </div>

        <div className="information-container">
          {newsList.length > 0 ? (
            newsList.map((news) => (
              <ExpandableCard
                key={news.id}
                date={news.date}
                title={news.title}
                body={<div dangerouslySetInnerHTML={{ __html: news.body }} />} // 🔹 HTMLを直接表示
              />
            ))
          ) : (
            <p>お知らせがありません。</p>
          )}
        </div>
      </section>
    </>
  );
};

export default PageTransition(Top);
