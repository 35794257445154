import React from "react";

import { images } from "../assets/images";

import { DelayHero } from "../components/DelayHero/DelayHero";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

const Compliance = () => {
  return (
    <>
      <Helmet>
        <title>Compliance Policy | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>
      <DelayHero
        image={images.heroTop}
        headline="Compliance Policy"
        copy="コンプライアンスポリシー"
        showCopy={true}
        fullScreen={false}
      />
      <section className="policy-container">
        <h1>コンプライアンスポリシー</h1>

        <p>
          当社は、広く社会に向け企業活動を行うにあたり、役員及び社員一人一人が法令及び社内規程の他社会規範を遵守します。
        </p>

        <p>
          当社への信頼・信用を確固たるものにすべく、コンプライアンスポリシーを制定し意識向上とコンプライアンス体制の整備を図ります。
        </p>

        <h2>1. 法令・社会規範の遵守</h2>

        <p>
          法令や定款・社内規定はもとより社会規範の趣旨・目的を理解するとともに遵守し、公正な企業活動を行います。
        </p>

        <p>
          たとえ法的問題でなくとも、行うことが社会、周囲の期待に沿っているかを自問し、利益よりも正しい行動を選択します。
        </p>

        <h2>2. 社会的責任</h2>

        <p>
          社会の一員としての自覚をもち、社会の要請にこたえ、責任ある行動をとるように心がけます。
        </p>

        <h2>3. お客様満足の追及と信用・信頼の向上</h2>

        <p>
          安全、品質に優れたサービスを提供し、取引に際しては適切な情報提供、誠実なコミュニケーションを行います。
        </p>

        <p>お客様からの声は真摯に受け止め、サービスの提供に活かします。</p>

        <p>業務を誠実に行い、不正行為や不適切な情報発信・言動は慎みます。</p>

        <h2>4. 取引先の選定と公正な取引</h2>

        <p>
          取引先の選定にあたっては、品質やサービス内容、価格、信頼度などを総合的に判断して選定をおこないます。
        </p>

        <p>
          お客様、仕入先やその他取引先との公正な取引関係を維持し、事業を共に進めるパートナーとして、常に対等に節度をもって接します。
        </p>

        <h2>5. 公正な競争と知的財産の尊重</h2>

        <p>
          常に公正かつ自由な競争をします。また自社のアイデアや知的財産を保護するとともに、他者のアイデアや知的財産を尊重します。
        </p>

        <h2>6. 個人情報・プライバシーの保護</h2>

        <p>
          私たちは、個人情報を厳重に管理し、利用目的の範囲内でのみ利用します。
        </p>

        <p>
          また、従業員をはじめとする全てのステークホルダーのプライバシーを尊重します。
        </p>

        <h2>7. 適切な情報開示</h2>

        <p>
          保存するべき情報を適正に管理し、必要な時に正確な情報開示ができるような状態を常に整備します。
        </p>

        <p>
          適正な帳簿を作成し、不正な記録や誤解を招くような記録を行うことはしません。
        </p>

        <h2>8. 人権の尊重と健全な職場の形成</h2>

        <p>
          人権を尊重し、国籍・人種・民族・性別・性的指向・性自認・性表現・年齢・宗教・信条・社会的身分・障害の有無等を理由とする差別や嫌がらせを一切行いません。
        </p>

        <p>
          心身ともに安全で健康的な環境整備を進めるとともに、一人ひとりを公正に評価し、働きがいのある環境づくりに努めます。
        </p>

        <h2>9. 反社会的勢力の遮断</h2>

        <p>
          私たちは、社会の安全・秩序に脅威を与えるような反社会的勢力には毅然とした態度で臨み、一切の関係を持たず、取引を行わず、資金提供も行いません。
        </p>

        <h2>10. 経営者による問題解決姿勢</h2>

        <p>
          コンプライアンスポリシーに反するような事態が発生した場合は、経営者自らが、問題解決にあたる姿勢を明らかにし、原因究明、再発防止、情報開示に努めます。
        </p>
      </section>
    </>
  );
};

export default PageTransition(Compliance);
