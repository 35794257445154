import React, { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./CategoryFilter.module.scss";

export const CategoryFilter = ({
  categories = [],
  selectedCategory,
  onChangeCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // 外部クリックで閉じる
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={styles.categoryFilter} ref={dropdownRef}>
      <button
        className={styles.categoryFilter__button}
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* `selectedCategory` のチェックを追加 */}
        {categories.length > 0
          ? categories.find((cat) => cat?.id === selectedCategory)?.name ||
            "すべてのカテゴリー"
          : "すべてのカテゴリー"}

        <ExpandMoreIcon
          className={styles.categoryFilter__icon}
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        />
      </button>

      <div
        className={`${styles.categoryFilter__menu} ${
          isOpen ? styles.open : ""
        }`}
      >
        {categories.length > 0 ? (
          categories.map((cat, index) => (
            <div
              key={cat?.id || `category-${index}`} // ✅ `id` が `undefined` の場合、`index` を使用
              className={styles.categoryFilter__option}
              onClick={() => {
                onChangeCategory(cat?.id || "other"); // ✅ `id` が `undefined` の場合 `"other"`
                setIsOpen(false);
              }}
            >
              {cat?.name || "その他"}{" "}
              {/* ✅ `undefined` の場合、「その他」と表示 */}
            </div>
          ))
        ) : (
          <div className={styles.categoryFilter__option}>カテゴリーなし</div> // ✅ カテゴリーが取得できない場合
        )}
      </div>
    </div>
  );
};
