import React from "react";

import styles from "../styles/Contact.module.scss";

import { images } from "../assets/images";

import ContactForm from "../components/ContactForm/ContactForm";
import { DelayHero } from "../components/DelayHero/DelayHero";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroTop}
        headline="Contact"
        copy="お問い合わせ"
        showCopy={true}
        fullScreen={false}
      />

      <section className={styles.container}>
        <div className={styles.contactForm}>
          <ContactForm />
        </div>
      </section>
    </>
  );
};

export default PageTransition(Contact);
