import React from "react";

import styles from "../styles/Company.module.scss";

import { images } from "../assets/images";

import { PageTransition } from "../components/PageTransition/PageTransition";
import { DelayHero } from "../components/DelayHero/DelayHero";
import { SplitSection } from "../components/SplitSection/SplitSection";
import { Table } from "../components/Table/Table";
import { TextReveal } from "../components/TextReveal/TextReveal";
import { Helmet } from "react-helmet-async";

const Company = () => {
  return (
    <>
      <Helmet>
        <title>Company | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroCompany}
        headline="Company"
        copy="会社概要"
        showCopy={true}
      />

      <section className={`${styles.exectives} ${styles.companySection}`}>
        <TextReveal color="secondary">
          <h1 className={styles.heading}>経営陣紹介</h1>
        </TextReveal>

        <div className={styles.container}>
          <SplitSection
            image={images.exective1}
            imageFirst={true}
            eyebrow="CEO"
            heading="井手 庸介"
            subheading="YOSUKE IDE"
            copy={
              <>
                いまやりたいことを本気でやることができていますか？
                <br />
                本当は「やりたい」、「挑戦してみたい」のに、色々なことを理由に諦めてしまっていませんか？
                <br />
                <br />
                人間はやりたいことを本気になって夢中でやれている時が、一番輝いていると私は思います。
                <br />
                <br />
                子供の時を思い出してみてほしいです。
                <br />
                できる、できないは関係なく、「いま」やりたいことを夢中でやっていたのではないでしょうか？
                <br />
                私は「いま」日本一カッコいい軽貨物会社を最高の仲間と共につくっています。
                <br />
                <br />
                なぜか？
                <br />
                <br />
                現在、日本一カッコいい軽貨物会社が世の中に存在していないと思っているから、その存在になりたい。
                <br />
                軽貨物ドライバーという仕事を心から誇れる人で溢れた、カッコいい組織をつくりたい。
                <br />
                つくれるかどうかはわからないし、最適な作り方もわからないけど、つくりたい。
                <br />
                <br />
                いまはただ、それだけです。
                <br />
                <br />
                日本一カッコいい軽貨物会社をつくったときの仲間の顔、取り巻く環境の変化。
                <br />
                その存在になれた時に、業界に対してどんなインパクトを与えることができているか。
                <br />
                <br />
                これらの情景をイメージすると、心が躍りワクワクします。
                <br />
                <br />
                このビジョンをバカにされることもあるでしょう。
                <br />
                うまくいくわけがないと否定されることもあるでしょう。
                <br />
                <br />
                ただ未来どうなるかは、誰にもわかりません。
                <br />
                それであれば、いま自分自身がやりたいと反応することに、全力で自分と仲間の可能性を信じてやり切るだけです。
                <br />
                <br />
                一度きりの人生、これでもかってぐらいやりたいことをやって、ワクワクできる最高な人生にしていきましょう。
                <br />
                <br />
                常識にとらわれず、情熱に従って挑戦し続ける存在として、業界、そして社会に価値を生み出していきます。
                <br />
              </>
            }
          />
          <SplitSection
            image={images.exective2}
            imageFirst={true}
            eyebrow="COO"
            heading="増田 涼佑"
            subheading="RYOSUKE MASUDA"
            copy={
              <>
                皆さんが人生で最も熱中できていたシーンはいつですか？
                <br />
                高校野球をやっていた時、バンドをしていた時、
                <br />
                はたまた世界を旅していた時でしょうか？
                <br />
                <br />
                どれも最高です。
                <br />
                でも「今」じゃないのはもったいない！
                <br />
                <br />
                想像してみてください。
                <br />
                人生で最も熱中できていた時のあの熱量で仕事ができたら最高だと思いませんか？
                <br />
                <br />
                「何かに挑戦したいけど何をしたらいいのかがわからない」
                <br />
                「何か自分を変えるためのきっかけがほしい」
                <br />
                <br />
                そんな思いを持っている人にとって合同会社YUM
                JAMはピッタリの環境です。
                <br />
                弊社には「今」が人生で最高の時間だと言う仲間がいます。
                <br />
                それぞれが「今」を主人公として生きています。
                <br />
                <br />
                私自身は今、高校野球をやっていた時に近い感覚で仕事をしています。
                <br />
                大人になってからも青春ができている「今」が楽しくて仕方がありません。
                <br />
                <br />
                学生時代が一番楽しかったなんて人生はもったいない！
                <br />
                人生は学生時代の何倍も続きます！
                <br />
                <br />
                この文章を読んで「今」ではないシーンが思い浮かんでいる人はぜひ弊社に来てください。
                <br />
                熱中できるものを提供することをお約束します。
                <br />
                <br />
                熱い仲間たちと共に「日本一カッコいい軽貨物会社をつくる」というGOALに向かって人生に熱中しましょう！
              </>
            }
          />
        </div>
      </section>

      <section className={`${styles.overview} ${styles.companySection}`}>
        <TextReveal color="secondary">
          <h1 className={styles.heading}>会社概要</h1>
        </TextReveal>

        <Table
          data={[
            { label: "会社名", value: "合同会社YUM JAM" },
            { label: "英文社名", value: "YUM JAM LLC." },
            { label: "設立日", value: "2021年10月8日" },
            { label: "CEO", value: "井手 庸介" },
            { label: "資本金", value: "9,000,000円" },
            {
              label: "所在地",
              value: [
                "本店: 104-0061",
                "東京都中央区銀座一丁目22番11号 銀座大竹ビジデンス 2F",
                "支店: 151-0061",
                "東京都渋谷区初台1-51-1 初台センタービル514",
              ],
            },
            { label: "お問合せ先", value: "contact@yumjam.co.jp" },
            {
              label: "主要取引先",
              value: [
                "アマゾンジャパン合同会社",
                "株式会社インタースペース",
                "株式会社エニキャリ",
                "グーグル合同会社",
                "株式会社出前館",
                "株式会社ファンコミュニケーションズ",
                "ヤマト運輸株式会社",
                "株式会社IFREA",
                "株式会社LINGs",
                "Uber Japan株式会社",
                "Wolt Japan株式会社",
              ],
            },
            {
              label: "主要取引銀行",
              value: [
                "GMOあおぞらネット銀行株式会社",
                "楽天銀行株式会社",
                "東京東信用金庫",
              ],
            },
          ]}
        />
      </section>
    </>
  );
};

export default PageTransition(Company);
