import React from "react";

import { images } from "../assets/images";

import { DelayHero } from "../components/DelayHero/DelayHero";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroTop}
        headline="Privacy Policy"
        copy="プライバシーポリシー"
        showCopy={true}
        fullScreen={false}
      />

      <section className="policy-container">
        <h1>プライバシーポリシー</h1>

        <p>
          合同会社YUM
          JAM（以下、当社）は、ステークホルダーの皆様から取得した個人情報の重要性を認識し、保護することを当社の事業活動の基本であると共に経営上の最重要な課題の一つと考えています。
        </p>

        <p>
          皆様へ安心・安全・信頼のサービスを提供していくため、以下のような基本方針を定め全社員に周知徹底を図り、これを遵守しています。
        </p>

        <h2>1. 基本方針</h2>

        <ol>
          <li>
            当社は、個人情報保護法および関連するその他の法令・規範を遵守します。また、個人情報保護に関する社内規定（以下、当社規定）を定め、これを実施し、維持すると共に、継続的な改善に努めます。
          </li>
          <li>
            当社は、個人情報保護に関する管理体制を確立するとともに、当社規程を役員および従業員に周知し、その遵守徹底に努めます。
          </li>
          <li>
            当社は、個人情報をお客様に明示した利用目的の範囲内で取り扱います。また、当社はご提供頂いた個人情報を、お客様の同意がある場合または正当な理由がある場合を除き、第三者に開示または提供しません。
          </li>
          <li>
            当社は、個人情報を正確かつ最新の状態に保つとともに、個人情報への不正アクセス、個人情報の漏えい、滅失、き損等の予防に努め、情報セキュリティの向上、是正を継続的に実施します。
          </li>
          <li>
            当社は、個人情報に関するお問い合わせ、開示等のご請求に誠実かつ迅速に対応します。
          </li>
        </ol>

        <p style={{ textAlign: "right" }}>2021年10月8日制定</p>
        <p style={{ textAlign: "right" }}>2022年3月29日改定</p>
        <p style={{ textAlign: "right" }}>
          合同会社YUM JAM
          <br />
          代表 井手庸介
        </p>

        <h2>2. 個人情報の利用目的</h2>

        <p>
          当社は、個人情報をご提供頂く場合、予め個人情報の利用目的を明示し、その利用目的の範囲内で利用します。予め明示した利用目的の範囲を超えて、個人情報を利用する必要が生じた場合は、その旨をご連絡し、同意を頂いた上で利用します。当社が保有する個人情報の利用目的は下記の通りです。
        </p>

        <p>
          なお当社は、下記目的での利用が終了した後も履歴の確認、お問い合せへの対応等のために一定期間保持します。
        </p>

        <ol>
          <li>
            お客様に関する個人情報
            <ul>
              <li>商品、資料等の発送</li>
              <li>サービス、イベント等のご案内送付</li>
              <li>顧客サポート、メンテナンスの提供</li>
              <li>お問い合わせ・ご相談への対応</li>
              <li>サービス開発、アンケート調査等の実施</li>
              <li>契約の履行</li>
            </ul>
          </li>
          <li>
            業務委託契約配送パートナー様に関する個人情報
            <ul>
              <li>
                業務委託契約業務を遂行するうえで必要な情報の提供・連絡・及び情報共有をするための手段
              </li>
              <li>契約選考に際し、応募者の適格性を評価するため</li>
              <li>当社及び関係、協力先との契約業務管理</li>
              <li>お問い合わせ・ご相談への対応</li>
              <li>契約の履行</li>
              <li>報酬支払に関する手続き</li>
            </ul>
          </li>
          <li>
            業務委託加盟店開拓営業パートナー様に関する個人情報
            <ul>
              <li>
                業務委託契約業務を遂行するうえで必要な情報の提供・連絡・及び情報共有をするための手段
              </li>
              <li>契約選考に際し、応募者の適格性を評価するため</li>
              <li>当社及び関係、協力先との契約業務管理</li>
              <li>お問い合わせ・ご相談への対応</li>
              <li>契約の履行</li>
              <li>報酬支払に関する手続き</li>
            </ul>
          </li>
        </ol>

        <h2>3. 個人情報の適正な取得</h2>

        <p>当社では、個人情報の取得は、適法かつ公正な手段で行います。</p>

        <p>
          また当社では個人情報取得ののシステムとして一部、「Googleフォーム」を利用しています。
        </p>

        <p>
          Googleは独自のプライバシーポリシーを有しており、当社はこれに対して責任を負うものではありません。
        </p>

        <p>
          詳しくは、Google社の利用規約やプライバシーポリシーを確認いただきますようお願いいたします。
        </p>

        <a
          rel="noreferrer noopener"
          target="_blank"
          href="https://policies.google.com/privacy?hl=ja"
        >
          Googleのプライバシーポリシー
        </a>

        <h2>4. 個人情報の提供</h2>

        <ol>
          <li>
            当社は、次の場合を除き、お客様、業務委託契約配達パートナー様の個人情報を第三者に開示または提供しません。
            <ol>
              <li>
                お客様、業務委託契約配送パートナー様、業務委託加盟店開拓営業パートナー様の同意がある場合
              </li>
              <li>法令に基づく場合</li>
              <li>
                人の生命、身体又は財産の保護のために必要であって、お客様、業務委託契約配達パートナー様、業務委託加盟店開拓営業パートナー様の同意を取ることが困難な場合
              </li>
              <li>
                利用目的の達成に必要な範囲内において、取得した個人情報の全部または、一部を業務委託先に委託する場合
              </li>
              <li>
                合併、会社分割、営業譲渡その他の事由によって事業の承継が行われる場合
              </li>
            </ol>
          </li>
        </ol>

        <h2>5. 個人情報の管理及びセキュリティ</h2>

        <ol>
          <li>
            お客様の個人情報
            <br />
            当社では、応募者から取得した個人情報を、一般のユーザーがアクセスできない環境下にあるサーバにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは漏洩等が生じないよう努めます。
          </li>
          <li>
            業務委託契約先様の個人情報
            <br />
            当社では、業務委託契約希望者から取得した個人情報を、一般のユーザーがアクセスできない環境下にあるサーバにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは漏洩等が生じないよう努めます。
            <br />
            また、応募者から取得した個人情報について、契約先選定の結果、契約に至った契約希望者の個人情報については、当社業務委託契約先情報として当社社内規程に基づき保管・管理します。契約に至らなかった契約希望者の個人情報については、一定期間、将来の当社からの業務発注機会やイベント、サービス情報提供等の目的で、必要な範囲において保有します。履歴の確認、お問い合せへの対応等のために一定期間、必要な範囲において保有します。
          </li>
        </ol>

        <h2>6. 個人情報に関するお問い合わせ</h2>

        <p>
          お客様の個人情報の開示・訂正・削除等に関するお問い合わせは、お問い合わせフォームよりご連絡ください。
        </p>

        <h2>7. その他の事項</h2>

        <dl>
          <dt>1. アクセス情報について</dt>
          <dd>
            当サイトでは、より良いサービスをご提供させて頂くために、アクセスログ情報を取得する場合がございます。予めご了承下さい。アクセスログの取得はお客様の個人情報を特定することを目的としたものではありません。
          </dd>
        </dl>

        <dl>
          <dt>2. クッキーについて</dt>
          <dd>
            当サイトでは、より快適にご利用して頂くために、サイトの一部でクッキー
            (Cookie)を使用しております。クッキー及びIPアドレス情報については、それら単独では特定の個人を識別することができないため、個人情報とは考えておりません。なお、クッキー情報については、ブラウザの設定で拒否することが可能です。
          </dd>
        </dl>
      </section>
    </>
  );
};

export default PageTransition(Privacy);
