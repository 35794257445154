import React from "react";
import { Table } from "../Table/Table";
import styles from "./JobApplication.module.scss";

export const JobApplication = ({ image, heading, copy, jobDetails }) => {
  return (
    <div className={styles.job}>
      <div className={styles.job__hero}>
        <div className={styles.job__image}>
          <img src={image.url} alt={heading} />
        </div>

        <div className={styles.job__header}>
          <h2 className={styles.job__heading}>{heading}</h2>
          {/* ✅ `dangerouslySetInnerHTML` で HTML をレンダリング */}
          <div
            className={styles.job__copy}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        </div>
      </div>

      <div className={styles.job__table}>
        <Table data={jobDetails} />
      </div>
    </div>
  );
};
