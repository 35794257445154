import React, { useRef, useEffect } from "react";
import styles from "./ScrollGallery.module.scss";
import { Card } from "../Card/Card";
import { TextReveal } from "../TextReveal/TextReveal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const ScrollGallery = (props) => {
  const { title, cards, id } = props;
  const galleryId = id || `gallery-${Math.random().toString(36).substr(2, 9)}`;
  const sectionRef = useRef(null);
  const trackRef = useRef(null);
  const triggerRef = useRef(null);

  // スクロールアニメーションのセットアップ
  useEffect(() => {
    if (!sectionRef.current || !trackRef.current) return;

    // クリーンアップ関数
    const cleanupScrollTrigger = () => {
      if (triggerRef.current) {
        triggerRef.current.kill();
        triggerRef.current = null;
      }
    };

    // 既存のトリガーをクリーンアップ
    cleanupScrollTrigger();

    const section = sectionRef.current;
    const track = trackRef.current;

    // トラックの初期位置をリセット
    gsap.set(track, { x: 0 });

    // トラックの全幅とコンテナの表示幅の差を計算
    const totalScrollWidth = track.scrollWidth - section.offsetWidth;

    // カードを表示する十分な幅がない場合は早期リターン
    if (totalScrollWidth <= 0) return;

    // ピン留めの持続時間を決定するためのセクションの高さ
    // 通常はビューポートの高さの2倍程度が適切（より長いスクロールエリアを確保）
    const pinDuration = window.innerHeight * 1.5;

    // スクロールトリガーの作成
    triggerRef.current = ScrollTrigger.create({
      trigger: section,
      start: "center center",
      end: `+=${pinDuration}`, // ピン留めの持続時間
      pin: true, // セクションをピン留め
      pinSpacing: true, // 他の要素への影響を考慮
      markers: false, // 本番環境ではfalseに
      id: galleryId, // 一意のID
      scrub: 1, // スクロールとアニメーションの滑らかな同期
      onUpdate: (self) => {
        // スクロール進捗に基づいてトラックを移動
        gsap.to(track, {
          x: -totalScrollWidth * self.progress,
          ease: "none",
          duration: 0.1, // アップデート間の短い遷移時間
        });
      },
      onRefresh: () => {
        // ScrollTriggerがリフレッシュされた時に再計算
        const newTotalScrollWidth = track.scrollWidth - section.offsetWidth;
        if (newTotalScrollWidth <= 0) {
          cleanupScrollTrigger();
        }
      },
    });

    // リサイズイベントのハンドラー
    const handleResize = () => {
      clearTimeout(window.resizeTimer);
      window.resizeTimer = setTimeout(() => {
        // トリガーをリフレッシュして位置や寸法を再計算
        ScrollTrigger.refresh();
      }, 200);
    };

    window.addEventListener("resize", handleResize);

    // クリーンアップ
    return () => {
      window.removeEventListener("resize", handleResize);
      cleanupScrollTrigger();
      clearTimeout(window.resizeTimer);
    };
  }, [galleryId, cards]);

  return (
    <div className={styles.scrollGallery} id={galleryId}>
      {title && (
        <div className={styles.eyebrowContainer}>
          <TextReveal color="secondary">
            <h3 className={`${styles.eyebrow} ja-Medium`}>{title}</h3>
          </TextReveal>
        </div>
      )}

      {/* スクロールトリガーのトリガー要素 */}
      <div
        className={styles.scrollContainer}
        ref={sectionRef}
        data-gallery-id={galleryId}
      >
        {/* 横にスクロールするトラック */}
        <div className={styles.itemContainer} ref={trackRef}>
          <div className={styles.cardSet}>
            {cards.map((card, index) => (
              <Card
                key={index}
                subtitle={card.subtitle}
                title={card.title}
                imageSrc={card.imageSrc}
                link={card.link}
                lang={card.lang}
                extraContent={card.extraContent}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
