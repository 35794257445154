import React from "react";

import styles from "../styles/Recruit.module.scss";

import { images } from "../assets/images";

import { DelayHero } from "../components/DelayHero/DelayHero";
import { SplitSection } from "../components/SplitSection/SplitSection";
import { TextReveal } from "../components/TextReveal/TextReveal";

import { useJobData } from "../hooks/useJobData";

import { JobApplication } from "../components/JobApplication/JobApplication";

import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

const Recruit = () => {
  const { jobData } = useJobData();

  return (
    <>
      <Helmet>
        <title>Recruit | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroRecruit1}
        headline="Recruit"
        copy="採用情報"
        showCopy={true}
      />

      <section
        id="voice"
        className={`${styles.employees} ${styles.recruitSection}`}
      >
        <TextReveal color="secondary">
          <h1 className={styles.heading}>社員の声</h1>
        </TextReveal>

        <div className={styles.container}>
          <SplitSection
            image={images.employee1}
            imageFirst={true}
            jaEyebrow="プロドライバー"
            heading="圓山 洸輔"
            subheading="KOSUKE MARUYAMA"
            copy={
              <>
                軽貨物の仕事は初めてでしたが、非常にやりがいがあり、荷物をお渡しした時のお客様の喜ぶ顔だったり、大事な荷物を届ける責任感を感じれる、とても良いお仕事です。
                <br />
                今まで経験のした事がないほどの充実感を感じながら、毎日稼働させてもらってます。
                <br />
                <br />
                あまり委託会社で制服を用意していただける会社が少ない中、黒をベースとした制服を夏、冬で用意してもらえて凄くカッコいいです。
                <br />
                自分は、本当に私服でも着てしまってまうほど気に入ってます（笑）
                <br />
                車用のステッカーもあり、黒で乗ってる自分の車両に、ピッタシ合っててすごく気にってます。
                <br />
                また、自分の車両に会社のステッカーを貼る事で移動宣伝車にもなりますし、恥ずかしくない運転をしようと気をつけるため責任感も芽生えて、すごくいいです。
                <br />
                <br />
                最後に一言。
                <br />
                社風がすごくいいです。代表を筆頭にこんなにも暖かい会社は、初めて！
                <br />
                どんな人でも偏見なく、ウェルカムな感じが大好きです。
                <br />
                多様性が求められるので、このご時世に適応してる会社だと、私は思います！
              </>
            }
          />

          <SplitSection
            image={images.employee2}
            imageFirst={true}
            jaEyebrow="ドライバー管理 / 採用"
            heading="大石 龍樹"
            subheading="RYUKI OISHI"
            copy={
              <>
                入社してまだ1年未満ですが、入社時と比べると驚くほど自分自身を変えることができました。
                <br />
                入社当初は緊張や不安からくる恐怖があり、人前に立つと何も話すことができなくなったという、苦い思い出があります。
                <br />
                <br />
                しかし、仕事をしていく中で徐々に人前で話すことに恐怖や緊張感も無くなってきて、自信をもって業務をすることができています。
                <br />
                自分を変えることができたのは、代表、副代表、会社のメンバーが、今までに見たことのないくらい熱い想いをもっている人たちだからです。
                <br />
                <br />
                この会社にいると、自分自身も熱くなって目標に向かって頑張れています！
                <br />
                仕事が嫌、会社に行きたくないなんて思う事すら無くなり、1日1日が楽しく充実しています！
              </>
            }
          />

          <SplitSection
            image={images.employee3}
            imageFirst={true}
            jaEyebrow="バックオフィス / 経理"
            heading="加藤 里香"
            subheading="RIKA KATO"
            copy={
              <>
                目指すGOALに向かって、社員一同本気で取り組んでいる会社です。
                <br />
                また、社員の「挑戦したい」という気持ちを社内メンバー全員が尊重してくれます。
                <br />
                私自身入社当初の仕事から業務内容は変わっており、今では自分の特技を活かせる業務や、挑戦してみたかった経理業務を一から学んでいます。
                <br />
                自分の意見を発信しやすい環境が整っているからこそ、実現したことです。
                <br />
                因みに仕事だけではなく、プライベートの話もできるくらい社員の方とは仲良しです♪
                <br />
                <br />
                現在課題が多い軽貨物業界ですがそこに革命をもたらし、軽貨物のイメージを変える会社だと確信しています。
                <br />
                社長含めメンバー全員が目指すゴールに向かって次々とアクションを起こしていく姿を見ているからこそ、このように感じることができます。
                <br />
                この会社の成長のサポートができる今の仕事が本当に楽しいです！
              </>
            }
          />
        </div>
      </section>

      <section className={`${styles.applications} ${styles.recruitSection}`}>
        <TextReveal color="secondary">
          <h1 className={styles.heading}>募集要項</h1>
        </TextReveal>

        <div className={styles.container}>
          {jobData.map((job) => (
            <JobApplication
              key={job.id}
              image={job.image}
              heading={job.heading}
              copy={job.copy}
              jobDetails={job.jobDetails}
            />
          ))}
        </div>
      </section>

      <section className={`${styles.info} ${styles.recruitSection}`}>
        <div className={styles.container}>
          <SplitSection
            image={images.uniform2}
            imageFirst={false}
            subheading="ドライバー採用で大事にしていること"
            copy={
              <>
                私たちは、日本一カッコいい軽貨物会社を目指しています。
                <br />
                そのため自社ブランディングを大事にしており、弊社への理念やビジョンに共感し、夢を一緒に目指していける方、見た目も在り方も共に追求していける方を歓迎します！
              </>
            }
          />

          <SplitSection
            image={images.meeting}
            imageFirst={false}
            subheading="本部スタッフ、現場管理、ドライバーが一同に集まる社員総会"
            copy={
              <>
                YUM
                JAMに所属するメンバーが一同に集まる社員総会を定期的に実施しております。
                <br />
                目的は、会社の想いやビジョン、今後の計画を共有し、全員で同じ方向を向いて、夢を追っていくためです。
                <br />
                また、全員から意見をいただいた上で、今後の計画を決めていく重要な会となっています。
              </>
            }
          />

          <SplitSection
            image={images.uniform1}
            imageFirst={false}
            subheading="こだわりのユニフォーム"
            copy={
              <>
                ブランドイメージに沿ったユニフォームを支給しています。（任意着用）
                <br />
                私服でも着れるユニフォームをコンセプトに、シルエットや形、機能にこだわった、どこよりもカッコいいユニフォームを用意しています。
              </>
            }
          />

          <SplitSection
            image={images.sticker}
            imageFirst={false}
            subheading="こだわりの車両ステッカー"
            copy={
              <>
                自慢のロゴを貼って日々の配達を行うことができる、ステッカーも希望者に支給しております！
                <br />
                カッコいいから貼りたいという声や、看板を背負って配達することで、責任感をもって働くことができる！と評判のステッカーです。
              </>
            }
          />
        </div>
      </section>
    </>
  );
};

export default PageTransition(Recruit);
