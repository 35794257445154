import styles from "../styles/ContactThanks.module.scss";

import { PageTransition } from "../components/PageTransition/PageTransition";
import { Button } from "../components/Button/Button";

const ContactThanks = () => {
  return (
    <div className={styles.contactThanks}>
      <h1>お問い合わせありがとうございます。</h1>
      <p>内容を確認次第、ご返信いたします。</p>

      <Button text="TOPへ戻る" link="/" />
    </div>
  );
};

export default PageTransition(ContactThanks);
