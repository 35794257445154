import React from "react";
import styles from "./Table.module.scss";

export const Table = ({ data }) => {
  return (
    <table className={styles.table}>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <th>{row.label}</th>
            <td>
              {Array.isArray(row.value)
                ? row.value.map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      {idx < row.value.length - 1 && <br />}
                      {/* ✅ 最後の要素には <br /> を追加しない */}
                    </React.Fragment>
                  ))
                : row.value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
