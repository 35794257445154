import React, { useState } from "react";

import styles from "./Navigation.module.scss";

import NavigationLogo from "./NavigationLogo";
import NavigationList from "./NavigationList";
import NavigationDrawer from "./NavigationDrawer";

const Navigation = (props) => {
  const { logo } = props;

  const navArray = [
    "/",
    "/about",
    "/company",
    "/services",
    "/recruit",
    "/blog",
    "/contact",
  ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={styles.navigation}>
        <NavigationLogo logo={logo} />

        <NavigationList
          navArray={navArray}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>

      <NavigationDrawer
        navArray={navArray}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleNav={toggleNav}
      />
    </>
  );
};

export default Navigation;
