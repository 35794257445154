import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { getPostDetail, getAllCategoriesMap } from "../services/api";
import styles from "../styles/BlogDetail.module.scss";
import { DelayHero } from "../components/DelayHero/DelayHero";
import { Button } from "../components/Button/Button";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

// 日付フォーマット関数
const formatDate = (dateString) => {
  if (!dateString) return "";
  return new Date(dateString).toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

const BlogDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [categoriesMap, setCategoriesMap] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // 記事詳細データを取得
  const fetchPostDetail = useCallback(async () => {
    if (!id) return;

    setLoading(true);
    try {
      const data = await getPostDetail(id);
      if (!data) {
        console.warn(`記事 ID: ${id} のデータが見つかりませんでした。`);
      }
      setPost(data);
    } catch (error) {
      console.error("記事詳細の取得に失敗しました", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  // カテゴリー情報を取得
  const fetchCategories = useCallback(async () => {
    try {
      const data = await getAllCategoriesMap();
      setCategoriesMap(data);
    } catch (error) {
      console.error("カテゴリ一覧の取得に失敗しました", error);
    }
  }, []);

  useEffect(() => {
    fetchPostDetail();
    fetchCategories();
  }, [fetchPostDetail, fetchCategories]);

  // 一覧ページに戻る
  const handleBack = () => {
    navigate(-1);
  };

  // 記事が見つからない場合
  if (!loading && !post) {
    return (
      <div className={styles.errorContainer}>
        <p className={styles.error}>記事が見つかりません。</p>
        <Button
          onClick={() => navigate("/blog")}
          text="ブログ一覧へ"
          forward={false}
        />
      </div>
    );
  }

  // カテゴリー名を取得
  const categoryNames =
    Array.isArray(post?.category) && post.category.length > 0
      ? post.category
          .map((catId) => categoriesMap[catId] || "未分類")
          .join(", ")
      : "カテゴリーなし";

  // デフォルト画像
  const defaultImage = "../assets/images/screenshot.png";

  return (
    <>
      <Helmet>
        <title>{post?.title || "タイトルなし"} | YUM JAM</title>
        <meta
          name="description"
          content={post?.excerpt || "ブログ記事の詳細です。"}
        />
        <meta property="og:title" content={post?.title || "タイトルなし"} />
        <meta
          property="og:description"
          content={post?.excerpt || "ブログ記事の詳細です。"}
        />
        <meta
          property="og:image"
          content={post?.eyecatch?.url || defaultImage}
        />
        <meta name="twitter:title" content={post?.title || "タイトルなし"} />
        <meta
          name="twitter:description"
          content={post?.excerpt || "ブログ記事の詳細です。"}
        />
        <meta
          name="twitter:image"
          content={post?.eyecatch?.url || defaultImage}
        />
      </Helmet>

      {loading ? (
        <div className={styles.loadingContainer}>
          <ClipLoader color="#919191" size={50} />
        </div>
      ) : (
        <>
          <DelayHero
            image={post?.eyecatch?.url || defaultImage}
            jaHeadline={post?.title || "タイトルなし"}
            copy={formatDate(post?.publishedAt)}
            showCopy={true}
            fullScreen={false}
            truncate={true}
          />
          <section className={styles.blogDetail}>
            <div className={styles.blogDetail__meta}>
              <span className={styles.blogDetail__date}>
                {formatDate(post?.publishedAt)}
              </span>
              <span className={styles.blogDetail__category}>
                {categoryNames}
              </span>
            </div>

            <h1 className={styles.blogDetail__title}>
              {post?.title || "タイトルなし"}
            </h1>

            <div className={styles.blogDetail__content}>
              {post?.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: window.DOMPurify
                      ? window.DOMPurify.sanitize(post.content)
                      : post.content,
                  }}
                />
              ) : (
                <p>コンテンツがありません。</p>
              )}
            </div>

            <div className={styles.blogDetail__button}>
              <Button onClick={handleBack} text="一覧に戻る" forward={false} />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default PageTransition(BlogDetail);
