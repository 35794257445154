import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname, hash } = useLocation();
  const animationDuration = 600; // ✅ PageTransition の duration に合わせる
  const extraDelayForHash = 600; // ✅ hash の場合は追加の待機時間
  const offset = 96; // ✅ 画面上部に 96px の余白を確保

  useEffect(() => {
    if (hash) {
      // ✅ ページの完全レンダリングを待ってからスクロール
      setTimeout(() => {
        const targetElement = document.getElementById(hash.replace("#", ""));
        if (targetElement) {
          const elementPosition =
            targetElement.getBoundingClientRect().top + window.scrollY;
          if ("requestIdleCallback" in window) {
            requestIdleCallback(() => {
              window.scrollTo({
                top: elementPosition - offset, // ✅ 96px 上に調整
                behavior: "smooth",
              });
            });
          } else {
            window.scrollTo({
              top: elementPosition - offset, // ✅ 96px 上に調整
              behavior: "smooth",
            });
          }
        }
      }, animationDuration + extraDelayForHash);
    } else {
      // ✅ `PageTransition` が完了したら即スクロール
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, animationDuration);
    }
  }, [pathname, hash]);

  return null;
};
