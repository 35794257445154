import React from "react";

import styles from "../styles/Services.module.scss";

import { images } from "../assets/images";

import { DelayHero } from "../components/DelayHero/DelayHero";
import { SplitSection } from "../components/SplitSection/SplitSection";
import { TextReveal } from "../components/TextReveal/TextReveal";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroService}
        headline="Services"
        copy="事業概要"
        showCopy={true}
      />

      <section
        id="promise"
        className={`${styles.strength} ${styles.servicesSection}`}
      >
        <TextReveal color="secondary">
          <h1 className={styles.heading}>３つの約束</h1>
        </TextReveal>

        <div className={styles.container}>
          <SplitSection
            image={images.strength1}
            imageFirst={true}
            eyebrow=""
            heading=""
            subheading="プロフェッショナルな配送を約束します"
            copy={
              <>
                気持ち良い挨拶、コミュニケーションはもちろん、自分の仕事に誇りをもったプロフェッショナルが、真心を込めてお客様のお荷物を時間通り、丁寧に配達いたします。
              </>
            }
          />
          <SplitSection
            image={images.strength2}
            imageFirst={true}
            eyebrow=""
            heading=""
            subheading="期待を超える配送を約束します"
            copy={
              <>
                0.1ミリでも期待を超えるサービスを提供しようとする意識をもって日々対応します。
                <br />
                月末に満足度アンケートに回答していただくことで、改善点がある場合は、即改善します。
              </>
            }
          />
          <SplitSection
            image={images.strength3}
            imageFirst={true}
            eyebrow=""
            heading=""
            subheading="プライドをもった在り方を約束します"
            copy={
              <>
                日本一カッコいい軽貨物会社の看板を背負う覚悟をもったドライバーが所属しています。
                <br />
                自分の仕事を誇り、プライドをもって、日々、荷物と笑顔を届けています。
              </>
            }
          />
        </div>
      </section>

      <section className={`${styles.info} ${styles.servicesSection}`}>
        <TextReveal color="secondary">
          <h1 className={styles.heading}>事業内容</h1>
        </TextReveal>

        <ul>
          <li>
            <h2>軽貨物運送</h2>
            <p>
              大手宅配をはじめとして、ネットスーパー、フードデリバリー、スポット便など幅広いジャンルの運送を行なってます。
            </p>
          </li>

          <li>
            <h2>Webメディア</h2>
            <p>
              フードデリバリー情報メディア
              <a
                href="https://www.yumjam.co.jp/delivery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                「デリバリー配達員によるデリバリー配達員のためのブログ」
              </a>
              を運営しております。
            </p>
          </li>

          <li>
            <h2>フードデリバリー出店登録支援</h2>
            <p>
              飲食店がフードデリバリーサービスを導入したい際の出店サポートを行っております。
              <br />
              <a
                href="https://www.yumjam.co.jp/delivery/ubereats-registration"
                target="_blank"
                rel="noopener noreferrer"
              >
                ウーバーイーツ店舗登録・出店方法
              </a>
              、
              <a
                href="https://www.yumjam.co.jp/delivery/demaecan-merchant"
                target="_blank"
                rel="noopener noreferrer"
              >
                出前館店舗登録・出店方法
              </a>
            </p>
          </li>
          <li>
            <h2>鯔背屋</h2>
            <p>
              粋なフードデリバリー文化を創造するために、配達バッグをカッコよくできるグッズを販売しております。
              <br />
              <a
                href="https://inaseya.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                詳細はこちら
              </a>
            </p>
          </li>
        </ul>
      </section>
    </>
  );
};

export default PageTransition(Services);
