import React from "react";
import styles from "./Card.module.scss";
import { Link } from "react-router-dom";

export const Card = ({
  subtitle,
  title,
  imageSrc,
  link,
  lang,
  extraContent,
}) => {
  const cardAttr = extraContent
    ? `${styles.card} ${styles.voiceCard}`
    : styles.card;

  return (
    <Link to={link} className={cardAttr}>
      <div className={styles.cardHead}>
        <h4 className="ja-Small">{subtitle}</h4>
        <h5 className={`${lang}-Medium`}>{title}</h5>
      </div>

      <div className={styles.cardPicture}>
        <img src={imageSrc} alt={title} />
      </div>

      <div className={styles.cardButton}>
        {extraContent && <>{extraContent}</>}
        <span></span>
        <span></span>
      </div>
    </Link>
  );
};
