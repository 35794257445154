import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ContactForm.module.scss";
import { sendContact } from "../../services/api"; // API呼び出し
import { Button } from "../Button/Button"; // ✅ 送信ボタンのスタイル適用

const ContactForm = () => {
  const [formData, setFormData] = useState({
    last_name: "",
    first_name: "",
    last_name_kana: "",
    first_name_kana: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value || "" });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.last_name.trim())
      newErrors.last_name = "姓を入力してください";
    if (!formData.first_name.trim())
      newErrors.first_name = "名を入力してください";
    if (!formData.last_name_kana.trim())
      newErrors.last_name_kana = "姓（フリガナ）を入力してください";
    if (!formData.first_name_kana.trim())
      newErrors.first_name_kana = "名（フリガナ）を入力してください";
    if (!formData.email.trim()) {
      newErrors.email = "メールアドレスを入力してください";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "有効なメールアドレスを入力してください";
    }
    if (!formData.company.trim())
      newErrors.company = "御社名を入力してください";
    if (!formData.phone.trim()) {
      newErrors.phone = "電話番号を入力してください";
    } else if (!/^\d{10,11}$/.test(formData.phone)) {
      newErrors.phone = "正しい電話番号を入力してください（ハイフンなし）";
    }
    if (!formData.message.trim())
      newErrors.message = "お問い合わせ内容を入力してください";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setStatus("送信中...");
    try {
      console.log("送信データ:", formData);
      await sendContact(formData);
      setStatus(null);
      setFormData({
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        email: "",
        company: "",
        phone: "",
        message: "",
      });
      navigate("/contact-thanks");
    } catch (error) {
      setStatus("送信に失敗しました。もう一度お試しください。");
      console.error("送信エラー:", error);
    }
  };

  return (
    <form className={styles.contactForm} onSubmit={handleSubmit}>
      <div className={styles.nameFields}>
        <label className={styles.label}>
          姓
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.last_name && (
            <p className={styles.error}>{errors.last_name}</p>
          )}
        </label>
        <label className={styles.label}>
          名
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.first_name && (
            <p className={styles.error}>{errors.first_name}</p>
          )}
        </label>
      </div>
      <div className={styles.nameFields}>
        <label className={styles.label}>
          姓（フリガナ）
          <input
            type="text"
            name="last_name_kana"
            value={formData.last_name_kana}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.last_name_kana && (
            <p className={styles.error}>{errors.last_name_kana}</p>
          )}
        </label>
        <label className={styles.label}>
          名（フリガナ）
          <input
            type="text"
            name="first_name_kana"
            value={formData.first_name_kana}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.first_name_kana && (
            <p className={styles.error}>{errors.first_name_kana}</p>
          )}
        </label>
      </div>
      <label className={styles.label}>
        メールアドレス
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className={styles.input}
        />
        {errors.email && <p className={styles.error}>{errors.email}</p>}
      </label>
      <label className={styles.label}>
        御社名
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
          required
          className={styles.input}
        />
        {errors.company && <p className={styles.error}>{errors.company}</p>}
      </label>
      <label className={styles.label}>
        電話番号
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          className={styles.input}
        />
        {errors.phone && <p className={styles.error}>{errors.phone}</p>}
      </label>
      <label className={styles.label}>
        お問い合わせ内容
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className={styles.textarea}
        />
        {errors.message && <p className={styles.error}>{errors.message}</p>}
      </label>
      <Button
        text="送信"
        onClick={handleSubmit}
        forward={true}
        textAlign="end"
        color="variant"
      />
      {status && <p className={styles.status}>{status}</p>}
    </form>
  );
};

export default ContactForm;
