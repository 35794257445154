import React from "react";
import styles from "./Pagination.module.scss";

import { PaginationButton } from "../PaginationButton/PaginationButton";

export const Pagination = ({
  currentPage,
  totalCount,
  perPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalCount / perPage); // 総ページ数を計算
  const pages = [...Array(totalPages).keys()].map((n) => n + 1); // 1からtotalPagesまでの配列を作成

  return (
    <div className={styles.pagination}>
      {/* 前へボタン：1ページ目では無効化 */}
      <PaginationButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 1}
        forward={false}
      ></PaginationButton>
      {/* 各ページ番号ボタン */}
      {pages.map((pageNum) => (
        <button
          key={pageNum}
          onClick={() => onPageChange(pageNum)}
          className={
            pageNum === currentPage
              ? `${styles.pagination__button} ${styles["pagination__button--active"]}` // 現在ページの場合はアクティブ用スタイルを適用
              : styles.pagination__button
          }
        >
          {pageNum}
        </button>
      ))}
      {/* 次へボタン：最終ページでは無効化 */}
      <PaginationButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
        forward={true}
      ></PaginationButton>
    </div>
  );
};
