import { useState, useEffect } from "react";
import { getJobData } from "../services/api";

export const useJobData = () => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const data = await getJobData();
        console.log("`getJobData()` のレスポンス:", data); // 取得データを確認
        setJobData(data);
      } catch (err) {
        console.error("求人情報の取得に失敗しました:", err);
        setError("求人情報の取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, []);

  return { jobData, loading, error };
};
