import React, { useEffect, useState } from "react";
import styles from "./LoadingScreen.module.scss";
import logo from "../../assets/icons/logo.svg";

export const LoadingScreen = ({ isLoading, onFinish }) => {
  const [progress, setProgress] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [shouldRender, setShouldRender] = useState(true); // マウント管理

  useEffect(() => {
    if (!isLoading) return;

    const startTime = performance.now();
    const duration = 1200; // 1.2秒で 80% まで進行

    const animateProgressTo80 = (timestamp) => {
      const elapsed = timestamp - startTime;
      const percentage = Math.min((elapsed / duration) * 80, 80);
      setProgress(percentage);

      if (elapsed < duration) {
        requestAnimationFrame(animateProgressTo80);
      } else {
        setProgress(80);
        animateProgressTo100();
      }
    };

    const animateProgressTo100 = () => {
      const startTime100 = performance.now();
      const duration100 = 200; // 0.2秒で 100% へ

      const animateTo100 = (timestamp) => {
        const elapsed = timestamp - startTime100;
        const percentage = Math.min(80 + (elapsed / duration100) * 20, 100);
        setProgress(percentage);

        if (elapsed < duration100) {
          requestAnimationFrame(animateTo100);
        } else {
          setProgress(100);
          setTimeout(() => {
            setFadeOut(true);
            setTimeout(() => {
              setShouldRender(false); // フェードアウト完了後に削除
              onFinish();
            }, 500);
          }, 300);
        }
      };

      requestAnimationFrame(animateTo100);
    };

    requestAnimationFrame(animateProgressTo80);
  }, [isLoading, onFinish]);

  if (!shouldRender) return null; // フェードアウト完了後に削除

  return (
    <div className={`${styles.loadingScreen} ${fadeOut ? styles.fadeOut : ""}`}>
      <div className={styles.backgroundAnimation}></div>

      <img src={logo} alt="Site Logo" className={styles.logo} />

      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{ width: `${progress}%` }} // `progress` の値に基づいて `width` を変更
        ></div>
      </div>

      <p className={styles.loadingText}>{Math.round(progress)}%</p>
    </div>
  );
};
