import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import styles from "./SectionIntro.module.scss";

import { TextReveal } from "../TextReveal/TextReveal";
import { Button } from "../Button/Button";

export const SectionIntro = (props) => {
  const { image, eyebrow, headline, body, link } = props;

  return (
    <ParallaxBanner
      layers={[
        {
          image: image,
          speed: -12,
        },
      ]}
    >
      <div className={styles.sectionIntro}>
        <div className={styles.sectionIntro__content}>
          <div className={styles.sectionIntro__header}>
            <TextReveal color="secondary">
              <h1 className="en-Medium">{eyebrow}</h1>
            </TextReveal>

            <TextReveal color="secondary">
              <h2 className="ja-Large">{headline}</h2>
            </TextReveal>
          </div>

          <TextReveal>
            <p className="ja-Small">{body}</p>
          </TextReveal>

          <Button text="See more" link={link} />
        </div>
      </div>
    </ParallaxBanner>
  );
};
