import React, { useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { HelmetProvider } from "react-helmet-async";
import "./styles/globals.scss";
import "./styles/reset.scss";
import "./styles/typography.scss";
import RouterConfig from "./routes/RouterConfig";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { ScrollToTop } from "./components/ScrollTop/ScrollTop";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <HelmetProvider>
      <ParallaxProvider>
        <Header />
        <ScrollToTop />
        <main>
          <RouterConfig />
        </main>
        <Footer />
        {/* ✅ `isLoading` に関係なく LoadingScreen をレンダリング */}
        <LoadingScreen
          isLoading={isLoading}
          onFinish={() => setIsLoading(false)}
        />
      </ParallaxProvider>
    </HelmetProvider>
  );
};

export default App;
