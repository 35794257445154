import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "../styles/BlogList.module.scss";
import { useNavigate } from "react-router-dom";
import { getPosts, getAllCategoriesMap, getLatestPost } from "../services/api"; // `getLatestPost` を追加
import { ClipLoader } from "react-spinners";
import { BlogCard } from "../components/BlogCard/BlogCard";
import { Pagination } from "../components/Pagination/Pagination";
import { CategoryFilter } from "../components/CategoryFilter/CategoryFilter";
import { DelayHero } from "../components/DelayHero/DelayHero";
import { PageTransition } from "../components/PageTransition/PageTransition";
import { Helmet } from "react-helmet-async";

const PER_PAGE = 10;

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([{ id: "all", name: "すべて" }]);
  const [latestEyecatch, setLatestEyecatch] = useState("/path/to/no-image.jpg"); // 最新記事のアイキャッチ用
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // API からブログ記事を取得
  const fetchPosts = useCallback(async (page, category) => {
    setLoading(true);
    try {
      const categoryParam = category !== "all" ? category : ""; // `"all"` の場合 `""` にする
      const data = await getPosts({ page, categoryId: categoryParam });

      if (!data.contents.length) {
        console.warn("記事が取得できませんでした。");
      }

      setPosts(data.contents);
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error("ブログ記事の取得に失敗しました", error);
    } finally {
      setLoading(false);
    }
  }, []);

  // カテゴリ一覧を取得
  const fetchCategories = useCallback(async () => {
    try {
      const categoriesMap = await getAllCategoriesMap();
      const categoriesArray = Object.entries(categoriesMap).map(
        ([id, name]) => ({
          id,
          name,
        })
      );
      setCategories([{ id: "all", name: "すべて" }, ...categoriesArray]);
    } catch (error) {
      console.error("カテゴリ一覧の取得に失敗しました", error);
    }
  }, []);

  // 最新記事のアイキャッチを取得
  useEffect(() => {
    const fetchLatestPost = async () => {
      const latestPost = await getLatestPost();
      if (latestPost) {
        setLatestEyecatch(latestPost.eyecatch.url);
      }
    };

    fetchLatestPost();
    fetchCategories();
  }, [fetchCategories]);

  // ページ変更またはカテゴリ変更時に記事取得
  useEffect(() => {
    fetchPosts(currentPage, selectedCategory);
  }, [currentPage, selectedCategory, fetchPosts]);

  // ページ変更処理（無駄なレンダリング防止）
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleCategoryChange = useCallback(
    (categoryId) => {
      if (selectedCategory !== categoryId) {
        setSelectedCategory(categoryId);
        setCurrentPage(1);
        fetchPosts(1, categoryId); // カテゴリ変更時に記事リストをリロード
      }
    },
    [fetchPosts, selectedCategory]
  );

  // 記事詳細ページに遷移
  const handleClickPost = useCallback(
    (postId) => {
      navigate(`/blog/${postId}`);
    },
    [navigate]
  );

  // `posts` のデータを `useMemo` で最適化
  const filteredPosts = useMemo(() => posts, [posts]);

  return (
    <>
      <Helmet>
        <title>Blog | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={latestEyecatch} // 最新記事のアイキャッチを適用
        headline="Blog"
        copy="私たちを知っていただく最新情報"
        showCopy={true}
        fullScreen={false}
      />

      <section className={styles.container}>
        <CategoryFilter
          categories={categories}
          selectedCategory={selectedCategory}
          onChangeCategory={handleCategoryChange}
        />

        <div className={styles.blogList}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <ClipLoader color="#919191" size={48} />
            </div>
          ) : (
            <div className={styles.blogList__list}>
              {filteredPosts.map((post) => (
                <div
                  key={post.id}
                  onClick={() => handleClickPost(post.id)}
                  className={styles.blogList__item}
                >
                  <BlogCard
                    title={post.title}
                    publishedAt={post.publishedAt}
                    category={post.category || []}
                    eyecatch={post.eyecatch}
                  />
                </div>
              ))}
            </div>
          )}

          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            perPage={PER_PAGE}
            onPageChange={handlePageChange}
          />
        </div>
      </section>
    </>
  );
};

export default PageTransition(BlogList);
