import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Top from "../pages/Top";
import About from "../pages/About";
import Company from "../pages/Company";
import Services from "../pages/Services";
import Recruit from "../pages/Recruit";
import BlogList from "../pages/BlogList";
import BlogDetail from "../pages/BlogDetail";
import Contact from "../pages/Contact";
import ContactThanks from "../pages/ContactThanks";

import Privacy from "../pages/Privacy";
import Compliance from "../pages/Compliance";

const RouterConfig = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Top />} />
        <Route path="/about" element={<About />} />
        <Route path="/company" element={<Company />} />
        <Route path="/services" element={<Services />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact-thanks" element={<ContactThanks />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/compliance" element={<Compliance />} />
      </Routes>
    </AnimatePresence>
  );
};

export default RouterConfig;
