import React from "react";
import PropTypes from "prop-types";
import styles from "./BlogCard.module.scss";

export const BlogCard = ({
  title = "タイトルなし",
  publishedAt = new Date().toISOString(),
  category = [],
  eyecatch = { url: "", height: 0, width: 0 },
  isFeatured = false,
}) => {
  return (
    <div
      className={`${styles.blogCard} ${
        isFeatured ? styles["blogCard--featured"] : ""
      }`}
    >
      {/* アイキャッチ画像の表示 */}
      {eyecatch?.url ? (
        <img
          src={eyecatch.url}
          alt={`${title}のアイキャッチ画像`}
          className={styles.blogCard__eyecatch}
        />
      ) : (
        <div className={styles.blogCard__eyecatchPlaceholder}>No Image</div>
      )}

      <div className={styles.blogCard__content}>
        <div className={styles.blogCard__meta}>
          <span className={styles.blogCard__date}>
            {new Date(publishedAt).toLocaleDateString("ja-JP", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </span>

          {Array.isArray(category) && category.length > 0 ? (
            category.map((cat) => (
              <span
                key={cat.id || `category-${Math.random()}`}
                className={styles.blogCard__category}
              >
                {cat.name || "その他"}
              </span>
            ))
          ) : (
            <span className={styles.blogCard__category}>その他</span>
          )}
        </div>

        <h2 className={styles.blogCard__title}>{title}</h2>
      </div>
    </div>
  );
};

// ✅ PropTypes の型定義はそのまま使用可能
BlogCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  publishedAt: PropTypes.string,
  category: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  eyecatch: PropTypes.shape({
    url: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  isFeatured: PropTypes.bool,
};
