import { Link } from "react-router-dom";

import styles from "./NavigationLogo.module.scss";

const NavigationLogo = ({ logo }) => {
  return (
    <div className={styles.navigationLogo}>
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
    </div>
  );
};

export default NavigationLogo;
