import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Footer.module.scss";

export const Footer = () => {
  const location = useLocation(); // 現在のページのパスを取得

  // スクロールをハンドリングする関数
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.footer}>
      <div className={`${styles.footerNav} en-xLarge`}>
        {[
          { path: "/", label: "top" },
          { path: "/about", label: "about" },
          { path: "/company", label: "company" },
          { path: "/services", label: "services" },
          { path: "/recruit", label: "recruit" },
          { path: "/blog", label: "blog" },
          { path: "/contact", label: "contact" },
        ].map(({ path, label }) => (
          <Link
            key={path}
            to={path}
            className={location.pathname === path ? styles.active : ""}
          >
            {label}
          </Link>
        ))}
      </div>

      <div className={styles.footerContent}>
        <div className={styles.footerInfo}>
          <p className="ja-Small">YUM JAM LLC.</p>
          <p className="ja-xSmall">
            〒104-0061
            <br />
            東京都中央区銀座一丁目22番11号 銀座大竹ビジデンス 2F
          </p>
        </div>

        <p className="ja-xSmall">©2024 YUM JAM LLC.</p>

        <Link to={"/privacy"} className="ja-xSmall">
          Privacy Policy
        </Link>

        <Link to={"/compliance"} className="ja-xSmall">
          Compliance Policy
        </Link>

        {/* スクロール処理を onClick イベントで直接指定 */}
        <button className={styles.footerButton} onClick={scrollToTop}>
          <div className={styles.footerButtonArrow}></div>
        </button>
      </div>
    </div>
  );
};
