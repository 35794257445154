import React from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";

export const Button = ({
  text,
  link,
  onClick,
  forward = true,
  textAlign = "start",
  color = "primary",
}) => {
  // textAlign に対応するクラスを動的に適用
  const textAlignClass =
    textAlign === "center"
      ? styles.center
      : textAlign === "end"
      ? styles.end
      : styles.start;

  // color に対応するクラスを動的に適用
  const colorClass = color === "variant" ? styles.variant : styles.primary;

  return (
    <div className={`${styles.buttonContainer} ${textAlignClass}`}>
      <Link
        to={link}
        onClick={onClick}
        className={`${styles.btn} ${colorClass} en-Small`}
      >
        {forward ? (
          <>
            {text}
            <span className={styles.forward}></span>
          </>
        ) : (
          <>
            <span className={styles.back}></span>
            {text}
          </>
        )}
      </Link>
    </div>
  );
};
