import React from "react";
import { motion } from "framer-motion";
import styles from "./PageTransition.module.scss";

export const PageTransition = (OgComponent) => {
  return () => (
    <>
      <OgComponent />

      <motion.div
        className={styles["slide-in"]}
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 0.6, ease: [0.2, 1, 0.3, 1] }}
      />

      <motion.div
        className={styles["slide-out"]}
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 0.6, delay: 0.2, ease: [0.2, 1, 0.3, 1] }}
      />
    </>
  );
};
