import React, { useState } from "react";
import styles from "./ExpandableCard.module.scss";

export const ExpandableCard = ({ date, title, body }) => {
  // 初期状態を 'closed' に設定
  const [isOpen, setIsOpen] = useState(false);

  // クリック時に状態を切り替える
  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`${styles.expandableCard} ${
        isOpen ? styles.open : styles.closed
      }`}
      onClick={toggleCard}
    >
      <div className={styles.infoHead}>
        <h2 className="en-Small">{date}</h2>
        <h3 className="ja-Medium">{title}</h3>
      </div>

      <div className={styles.infoContent}>
        <div className="ja-Small">{body}</div>
      </div>

      <div className={styles.infoButtonContainer}>
        <div className={styles.infoButton}>
          <span className="en-Small">{isOpen ? "Close" : "See more"}</span>
        </div>

        <div className={styles.infoArrow}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};
