import { handleError } from "./client";

const API_ENDPOINTS = {
  NEWS: "https://wp.yumjam.co.jp/wp-json/wp/v2/news",

  JOBS: "https://wp.yumjam.co.jp/wp-json/wp/v2/jobs",

  BLOG: "https://wp.yumjam.co.jp/wp-json/wp/v2/posts",

  CATEGORIES: "https://wp.yumjam.co.jp/wp-json/wp/v2/categories",

  CONTACTS:
    "https://wp.yumjam.co.jp/wp-json/contact-form-7/v1/contact-forms/123/feedback",
};

// GET NEWS //

export const getNews = async () => {
  try {
    const response = await fetch(API_ENDPOINTS.NEWS);
    const data = await response.json();

    return data.map((news) => ({
      id: news.id,
      date: new Date(news.date).toLocaleDateString("ja-JP"), // 日本語の日付フォーマット
      title: news.title.rendered,
      body: news.content.rendered, // HTML形式の本文
    }));
  } catch (error) {
    console.error("お知らせの取得に失敗しました:", error);
    return [];
  }
};

// GET POSTS //

export const getPosts = async ({
  page = 1,
  categoryId = null,
  postId = null,
} = {}) => {
  const params = new URLSearchParams();
  params.append("per_page", 10);
  params.append("page", page);

  if (categoryId) {
    params.append("categories", categoryId);
  }

  const url = postId
    ? `${API_ENDPOINTS.BLOG}/${postId}`
    : `${API_ENDPOINTS.BLOG}?${params.toString()}`;

  try {
    const categoriesMap = await getAllCategoriesMap();
    const response = await fetch(url);
    const data = await response.json();

    return {
      contents: data.map((post) => ({
        id: post.id,
        title: post.title.rendered,
        publishedAt: post.date,
        eyecatch: post.featured_media_url
          ? { url: post.featured_media_url }
          : { url: "/path/to/no-image.jpg" },
        category: post.categories.map((catId) => ({
          id: String(catId), // ✅ `number` → `string` に変換
          name: categoriesMap[catId] || "未分類",
        })),
        content: post.content.rendered || "",
      })),
      totalCount: response.headers.get("X-WP-Total") || 0,
    };
  } catch (error) {
    console.error("ブログ記事の取得に失敗しました:", error);
    return { contents: [], totalCount: 0 };
  }
};

// GET LATSET POST //

export const getLatestPost = async () => {
  try {
    const response = await fetch(
      "https://wp.yumjam.co.jp/wp-json/wp/v2/posts?per_page=1&_embed"
    );
    const data = await response.json();

    if (data.length === 0) return null;

    // ✅ アイキャッチ画像を取得
    let eyecatchUrl = "/path/to/no-image.jpg";
    if (data[0]._embedded?.["wp:featuredmedia"]?.[0]?.source_url) {
      eyecatchUrl = data[0]._embedded["wp:featuredmedia"][0].source_url;
    } else if (data[0].featured_media_url) {
      eyecatchUrl = data[0].featured_media_url;
    }

    return {
      id: data[0].id,
      title: data[0].title.rendered,
      eyecatch: { url: eyecatchUrl },
    };
  } catch (error) {
    console.error("最新記事の取得に失敗しました:", error);
    return null;
  }
};

// GET POST DETAIL //

export const getPostDetail = async (postId) => {
  if (!postId) return null;

  try {
    const response = await fetch(
      `https://wp.yumjam.co.jp/wp-json/wp/v2/posts/${postId}?_embed`
    );
    const post = await response.json();

    // ✅ `featured_media_url` の取得
    let eyecatchUrl = "/path/to/no-image.jpg"; // デフォルト画像
    if (post._embedded?.["wp:featuredmedia"]?.[0]?.source_url) {
      eyecatchUrl = post._embedded["wp:featuredmedia"][0].source_url;
    } else if (post.featured_media_url) {
      eyecatchUrl = post.featured_media_url;
    }

    return {
      id: post.id,
      title: post.title.rendered,
      publishedAt: post.date,
      eyecatch: { url: eyecatchUrl },
      category: post.categories || [],
      content: post.content.rendered || "",
    };
  } catch (error) {
    console.error(`記事 ID: ${postId} の取得に失敗しました`, error);
    return null;
  }
};

// GET ALL CATEGORIES MAP //

export const getAllCategoriesMap = async () => {
  try {
    const response = await fetch(API_ENDPOINTS.CATEGORIES);
    const data = await response.json();

    return data.reduce((acc, category) => {
      acc[category.id] = category.name; // ✅ { 3: "ニュース", 5: "イベント" } の形式
      return acc;
    }, {});
  } catch (error) {
    handleError(error, "カテゴリ一覧の取得に失敗しました");
    return {};
  }
};

// GET JOB DATA //

export const getJobData = async () => {
  try {
    const response = await fetch(
      "https://wp.yumjam.co.jp/wp-json/wp/v2/jobs?_embed"
    );
    const data = await response.json();

    return data.map((job) => ({
      id: job.id,
      heading: job.title.rendered || "",
      copy: job.content.rendered || "",
      image: job._embedded?.["wp:featuredmedia"]?.[0]?.source_url
        ? {
            url: job._embedded["wp:featuredmedia"][0].source_url,
            height: 0,
            width: 0,
          }
        : { url: "", height: 0, width: 0 },
      jobDetails: Array.isArray(job.jobDetails) ? job.jobDetails : [], // ✅ `jobDetails` を取得
    }));
  } catch (error) {
    console.error("求人情報の取得に失敗しました:", error);
    return [];
  }
};

// SEND CONTACT //

export const sendContact = async (formData) => {
  try {
    console.log("送信データ:", formData); // ✅ 送信データのログを出力

    const response = await fetch(
      "https://wp.yumjam.co.jp/wp-json/wp/v2/send-contact/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      }
    );

    const data = await response.json();
    console.log("レスポンス:", response.status, data); // ✅ HTTPステータスコードとレスポンスを出力

    if (!response.ok) {
      throw new Error(
        data.message ||
          `サーバーエラーが発生しました（ステータス: ${response.status}）`
      );
    }

    return data;
  } catch (error) {
    console.error("問い合わせの送信に失敗しました:", error);
    return null;
  }
};
