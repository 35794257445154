import { Link, useLocation } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import styles from "./NavigationList.module.scss";

const NavigationList = (props) => {
  const { navArray, isOpen, setIsOpen } = props;
  const location = useLocation(); // ✅ 現在のページのパスを取得

  return (
    <nav className={styles.navigationList}>
      <ul>
        {navArray.map((path, index) => (
          <li
            key={index}
            className={`${styles.navItem} ${
              location.pathname === path ? styles.active : ""
            }`} // ✅ 現在のページなら `active` クラスを適用
          >
            <Link to={path}>{path === "/" ? "top" : path.slice(1)}</Link>
          </li>
        ))}
        <li style={{ zIndex: "1" }}>
          <Hamburger size={24} rounded toggled={isOpen} toggle={setIsOpen} />
        </li>
      </ul>
    </nav>
  );
};

export default NavigationList;
