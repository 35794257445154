import heroTop from "./250130-43.webp";
import heroAbout from "./250130-210.webp";
import heroService from "./250130-350.webp";
import heroRecruit1 from "./250130-176-1.webp";
import heroRecruit2 from "./250130-176-2.webp";
import heroCompany from "./250130-106-1.webp";

import mission from "./250130-17.webp";
import vision from "./250130-81.webp";
import value from "./250130-127.webp";

import strength1 from "./250130-71.webp";
import strength2 from "./250130-13.webp";
import strength3 from "./250130-3.webp";

import employee1 from "./250130-28.webp";
import employee2 from "./250130-92.webp";
import employee3 from "./250130-118.webp";

import exective1 from "./250130-267.webp";
import exective2 from "./250130-292.webp";

import job1 from "./250130-52.webp";
import job2 from "./250130-90.webp";

import uniform1 from "./250130-342.webp";
import uniform2 from "./250130-358.webp";

import meeting from "./IMG_6644.webp";
import sticker from "./250130-106-2.webp";

export const images = {
  heroTop,
  heroAbout,
  heroService,
  heroRecruit1,
  heroRecruit2,
  heroCompany,

  mission,
  vision,
  value,

  strength1,
  strength2,
  strength3,

  employee1,
  employee2,
  employee3,

  exective1,
  exective2,

  job1,
  job2,

  uniform1,
  uniform2,

  meeting,
  sticker,
};
