import { Link, useLocation } from "react-router-dom";
import styles from "./NavigationDrawer.module.scss";
import { useEffect } from "react";

const NavigationDrawer = (props) => {
  const { navArray, isOpen, toggleNav } = props;
  const location = useLocation(); // ✅ 現在のページのパスを取得

  const navArraywithDelay = navArray.map((path, index) => ({
    ...{ path },
    label: path === "/" ? "top" : path.slice(1),
    delay: `${0.3 + 0.1 * index}s`,
  }));

  // スクロール禁止用のイベントハンドラー
  const preventScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  useEffect(() => {
    if (isOpen) {
      // スクロールを禁止
      window.addEventListener("scroll", preventScroll, { passive: false });
      window.addEventListener("wheel", preventScroll, { passive: false });
      window.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      // スクロール禁止を解除
      window.removeEventListener("scroll", preventScroll);
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    }

    // クリーンアップ処理
    return () => {
      window.removeEventListener("scroll", preventScroll);
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    };
  }, [isOpen]);

  return (
    <div
      className={`${styles.navigationDrawer} ${
        isOpen ? styles.expanded : styles.collapsed
      }`}
    >
      <nav>
        <ul className={`en-xLarge ${styles.navList}`}>
          {navArraywithDelay.map((navItem, index) => (
            <li
              key={index}
              className={`${styles.navItem} ${
                location.pathname === navItem.path ? styles.active : ""
              }`} // ✅ 現在のページなら `active` クラスを適用
            >
              <div
                className={`${styles.linkContainer} ${
                  isOpen ? styles.active : ""
                }`}
                style={{ transitionDelay: isOpen ? navItem.delay : "0s" }}
              >
                <Link to={navItem.path} onClick={toggleNav}>
                  {navItem.label}
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default NavigationDrawer;
