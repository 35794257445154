import axios from "axios";

// Axiosインスタンスの作成
const client = axios.create({
  baseURL: process.env.REACT_APP_MICROCMS_BASE_URL, // 環境変数を適用
  headers: {
    "Content-Type": "application/json",
    "X-API-KEY": process.env.REACT_APP_MICROCMS_API_KEY, // 環境変数を適用
  },
});

// 共通エラーハンドリング
export const handleError = (error, message) => {
  console.error(`${message}:`, error.response?.data || error.message);
  throw error;
};

export default client;
