import React, { useEffect, useRef, useState } from "react";
import styles from "./SplitSection.module.scss";

export const SplitSection = (props) => {
  const {
    image,
    imageFirst = true,
    eyebrow,
    jaEyebrow,
    heading,
    subheading,
    copy,
  } = props;

  const [sectionHeight, setSectionHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setSectionHeight(contentRef.current.offsetHeight);
      }
    };

    // 初回実行
    updateHeight();

    // ResizeObserverを使用して要素のサイズ変更を監視
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    const observedElement = contentRef.current; // ここで現在の `contentRef.current` を変数に保存

    if (observedElement) {
      resizeObserver.observe(observedElement);
    }

    // クリーンアップ（安全な方法）
    return () => {
      if (observedElement) {
        resizeObserver.unobserve(observedElement);
      }
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      className={`${styles.splitSection} ${
        imageFirst ? styles.imageFirst : styles.contentFirst
      }`}
      style={{ gridAutoRows: `${sectionHeight}px` }}
    >
      {/* 画像がある場合のみ表示 */}
      {image && (
        <div className={styles.splitSection__image}>
          <img src={image} alt="splitSection__image" />
        </div>
      )}

      {/* コンテンツがある場合のみ表示 */}
      {(eyebrow || heading || subheading || copy) && (
        <div ref={contentRef} className={styles.splitSection__content}>
          <div className={styles.splitSection__header}>
            {/* 各要素が存在する場合のみ表示 */}
            {eyebrow && (
              <h2 className={styles.splitSection__eyebrow}>{eyebrow}</h2>
            )}
            {jaEyebrow && (
              <h2 className={styles.splitSection__jaEyebrow}>{jaEyebrow}</h2>
            )}
            {heading && (
              <h1 className={styles.splitSection__heading}>{heading}</h1>
            )}
            {subheading && (
              <h2 className={styles.splitSection__subheading}>{subheading}</h2>
            )}
          </div>

          {copy && <p className={styles.splitSection__copy}>{copy}</p>}
        </div>
      )}
    </div>
  );
};
