import React from "react";
import "../styles/about.scss";

import { images } from "../assets/images";

import { PageTransition } from "../components/PageTransition/PageTransition";
import { DelayHero } from "../components/DelayHero/DelayHero";
import { SplitSection } from "../components/SplitSection/SplitSection";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About | YUM JAM</title>
        <meta name="description" content="YUM JAM" />
      </Helmet>

      <DelayHero
        image={images.heroAbout}
        headline="About"
        copy="私たちについて"
        showCopy={true}
      />

      <div id="section-mvv">
        <section id="mission">
          <SplitSection
            image={images.mission}
            imageFirst={false}
            eyebrow="Mission"
            heading="Be foolish"
            subheading="常識に囚われない価値の創造"
            copy={
              <>
                バカでいい、違っていい。
                <br />
                心から思える純粋な「やりたい」に従おう。
                <br />
                常識に囚われない、自由な発想で挑戦し続けることこそが、YUM
                JAMの存在意義。
                <br />
                自らの存在により価値を生み出し、業界の当たり前をぶち壊す。
                <br />
                さあ、どデカい花火を打ち上げよう。
              </>
            }
          />
        </section>

        <section id="vision">
          <SplitSection
            image={images.vision}
            imageFirst={false}
            eyebrow="Vision"
            heading={
              <>
                日本一カッコいい
                <br />
                軽貨物会社を作る
              </>
            }
            subheading="見た目や在り方が唯一無二で尖った会社"
            copy={
              <>
                洗練されたロゴ、制服、身だしなみ。
                徹底的に「見た目」にこだわる。
                <br />
                明るい挨拶、プロフェッショナルな配送、立ち振る舞い、生き方。
                <br />
                徹底的に「在り方」にこだわる。
                <br />
                「軽貨物ドライバーの仕事をやっている」と
                <br />
                誇りを持って語れる人で溢れた組織をつくる。
              </>
            }
          />
        </section>

        <section id="value" className="valueContainer">
          <SplitSection
            image={images.value}
            imageFirst={false}
            eyebrow="Value"
            heading="主人公であれ"
            subheading="自分の人生の主人公は自分だけ"
          />

          <ul>
            <li>
              <h2>やりたいに従う</h2>
              <p>心から湧き出る純粋な「やりたい」に従おう。</p>
            </li>
            <li>
              <h2>人を愛する</h2>
              <p>自分を愛そう。関わる全ての人に感謝し、愛を持って関わろう。</p>
            </li>
            <li>
              <h2>約束を守る</h2>
              <p>
                自分との約束を守り、人との約束を守る。約束を守ることが自信になる。
              </p>
            </li>
            <li>
              <h2>責任をもつ</h2>
              <p>自分の行動、言動、選択に責任をもとう。その結果が、いまだ。</p>
            </li>
            <li>
              <h2>本気を出す</h2>
              <p>本気で自分の人生を生きよう。本気で人の人生に関わろう。</p>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default PageTransition(About);
