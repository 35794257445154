import React, { useState, useEffect } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { TextReveal } from "../TextReveal/TextReveal";

import styles from "./DelayHero.module.scss";

export const DelayHero = (props) => {
  const {
    image,
    headline,
    jaHeadline,
    copy,
    showCopy,
    fullScreen = true,
    truncate = false,
  } = props;

  const [parallaxSettings, setParallaxSettings] = useState([
    {
      image: image,
      speed: -20,
      scale: [0.8, 1.2],
      opacity: [2, 0],
    },
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setParallaxSettings([
          {
            image: image,
            speed: -8, // 速度を緩やかに
            scale: [0.8, 1.2], // スケールを調整
            opacity: [2.5, 0], // オパシティを変化
          },
        ]);
      } else {
        setParallaxSettings([
          {
            image: image,
            speed: -20,
            scale: [0.8, 1.2],
            opacity: [2, 0],
          },
        ]);
      }
    };

    // 初回実行
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [image]);

  return (
    <section
      className={`${styles.delayHero} ${
        fullScreen ? styles.fullScreen : styles.smallScreen
      }`}
    >
      <ParallaxBanner
        className={styles.ParallaxBanner}
        layers={parallaxSettings}
      >
        <div className={styles.marquee}>
          <div className={styles.viewportContent}>
            {headline && (
              <TextReveal color="secondary">
                <h1
                  className={`${styles.headline} ${
                    truncate ? styles.truncate : ""
                  }`}
                >
                  {headline}
                </h1>
              </TextReveal>
            )}
            {jaHeadline && (
              <TextReveal color="secondary">
                <h1
                  className={`${styles.jaHeadline} ${
                    truncate ? styles.truncate : ""
                  }`}
                >
                  {jaHeadline}
                </h1>
              </TextReveal>
            )}
            {showCopy && (
              <TextReveal color="secondary">
                <h2 className={styles.copy}>{copy}</h2>
              </TextReveal>
            )}
          </div>
        </div>
      </ParallaxBanner>
    </section>
  );
};
